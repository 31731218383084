<template>
  <div>
    <v-card>
      <v-card-title
        class="justify-center"
        style="
          background-color: #006a4e;

          color: aliceblue;
        "
      >
        Customers Daily Target Follow-up
      </v-card-title>

      <br />
      <v-container>
        <v-row>
          <v-layout>
            <v-flex xs2 md2>
              <template>
                <v-flex xs12 align="center">
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endDate"
                        label="اليوم"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="endDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">
                        إغلاق
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(date)"
                      >
                        حفظ
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-flex>
              </template>
            </v-flex>
          </v-layout>
        </v-row>
        <v-row>
          <v-flex xs12 md12 class="salestable">
            <template>
              <v-data-table
                :headers="headers"
                :items="items"
                hide-default-footer
                hide-default-header
                class="elevation-1"
              >
                <template v-slot:header="{ props: { headers } }">
                  <thead>
                    <tr>
                      <th v-for="(h, i) in headers" :class="h.class" :key="i">
                        <span>{{ h.text }}</span>
                      </th>
                    </tr>
                  </thead>
                </template>
              </v-data-table>
            </template>
          </v-flex>
        </v-row>
      </v-container>
    </v-card>
    <v-flex xs6 md6>
      <v-autocomplete
        :items="products"
        v-model="ProductId"
        color="white"
        item-text="productname"
        label="اسم المنتج"
        item-value="productNumber"
        required
      ></v-autocomplete>
    </v-flex>
    <v-card>
      <v-data-table
        :headers="headersTable"
        :items="ItemTable"
        sort-by="name"
        class="elevation-1 mt-3"
      >
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "market-segmentation",

  components: {
    // areadetails
  },

  data() {
    return {
      ActualMonthPlan: "",
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      users: [],
      ResponsibleId: { item: "جميع المندوبين", value: "0" },
      EndDateRules: [
        () =>
          this.endDate >= this.startDate ||
          "تاريخ النهاية لا يمكن أن يسبق  تاريخ البداية",
      ],
      modal: false,
      modal2: false,
      date: new Date().toISOString().substr(0, 10),
      date2: new Date().toISOString().substr(0, 10),

      headers: [
        { text: "Sales", value: "sales", class: "my-header-style" },
        {
          text: "Forecast",
          value: "ActualMonthPlan",
          class: "my-header-style",
        },
        { text: "Growth", value: "qrowth", class: "my-header-style" },
      ],
      customers: [],
      desserts: [],
      ItemTable: [],
      items: [],
      monthsNameArabic: [
        { name: "كانون الثاني", num: "0" },
        { name: "شباط", num: "1" },
        { name: "آذار", num: "2" },
        { name: "نيسان", num: "3" },
        { name: "أيار", num: "4" },
        { name: "حزيران", num: "5" },
        { name: "تموز", num: "6" },
        { name: "آب", num: "7" },
        { name: "أيلول", num: "8" },
        { name: "تشرين الأول", num: "9" },
        { name: "تشرين الثاني", num: "10" },
        { name: "كانون الأول", num: "11" },
      ],
      AnnualMonths: [],
      AnnualDaysOff: [],
      headersTable: [
        {
          text: "العميل",
          align: "start",
          filterable: false,
          value: "customerName",
        },
        { text: " الخطة", value: "target" },
        { text: "المبيع", value: "sumQuantity" },
        { text: "الحجم المختلف", value: "different" },
        { text: "الاختلاف%", value: "differentpercentage" },
        { text: "OPM 14", value: "productbyfactory" },
        { text: "المبيعات بالنسبة للمصنع الجديد", value: "salesbyfactory" },
      ],
      months: [
        { name: "كانون الثاني", num: "0" },
        { name: "شباط", num: "1" },
        { name: "آذار", num: "2" },
        { name: "نيسان", num: "3" },
        { name: "أيار", num: "4" },
        { name: "حزيران", num: "5" },
        { name: "تموز", num: "6" },
        { name: "آب", num: "7" },
        { name: "أيلول", num: "8" },
        { name: "تشرين الأول", num: "9" },
        { name: "تشرين الثاني", num: "10" },
        { name: "كانون الأول", num: "11" },
      ],
      thisMonth: "",
      products: [],
      ProductId: "",
    };
  },

  computed: {
    series() {
      let series = [
        {
          name: "Sales",
          data: [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5, 13, 9, 17, 2, 7, 5],
        },
      ];
      return series;
    },
  },

  watch: {
    endDate: function () {
      this.getAnnualPlan();
      // this.getTotaSlesForAllCustomertInDay(this.endDate)
    },
    ProductId: function () {
      this.getTotalMarketSales();
    },
  },

  created() {
    this.getTotalMarketSales();
    this.getProduct();
    this.getCustomer();
    this.getAnnualPlan();
  },

  methods: {
    getthisMonth(date) {
      date = new Date(date);

      let fulldate = date.getMonth();

      this.months.forEach((el) => {
        if (el.num == fulldate) {
          this.thisMonth = el.name;
        }
      });
    },
    getDaysInMonthAnnual(year, month) {
      return new Date(year, month, 0).getDate();
    },
    getthisMonthName(date) {
      let fulldate = date.getMonth();

      this.monthsNameArabic.forEach((el) => {
        if (el.num == fulldate) {
          this.thisMonth = el.name;
        }
      });
    },
    async getAnnualPlanByMonth(date) {
      let NumDaysOff = 0;

      let targetMonth = 0;

      date = new Date(date);
      this.getthisMonthName(date);

      const date1 = new Date(date);
      const currentYear = date1.getFullYear();
      const currentMonth = date1.getMonth() + 1;
      const NumberOfdays = this.getDaysInMonthAnnual(currentYear, currentMonth);

      this.AnnualMonths.forEach((el) => {
        if (el.month1 == this.thisMonth) {
          targetMonth = el.target;
        }
      });

      this.AnnualDaysOff.forEach((el) => {
        if (this.sameDay(el.day, date)) {
          NumDaysOff++;
        }
      });

      let NumDays = NumberOfdays - NumDaysOff;

      this.ActualMonthPlan = targetMonth / NumDays;
      this.getTotaSlesForAllCustomertInDay();
    },
    async getProduct() {
      const response = await this.ApiService.get(
        "https://salestools.yamamacement.com/api/getreportService/getAllProducts"
      );

      this.products = response.data.responseData;
      
      this.products.push({ productname: "total", productNumber: "total" });
    },
    async getTotaSlesForAllCustomertInDay() {
      this.items = [];
      let date = moment(this.endDate).format("MM/DD/YYYY");
      let url = `https://salestools.yamamacement.com/api/getreportService/getTotaSlesForAllCustomertInDay?fromDate=${date}`;

      const response = await this.ApiService.get(url);
      // JSON responses are automatically parsed.

      // JSON responses are automatically parsed.

      let sales = response.data[0].sumQuantity;

      let qrowth = `${(100 * sales) / this.ActualMonthPlan} % `;

      this.items.push({
        qrowth: qrowth,
        sales: sales,
        ActualMonthPlan: this.ActualMonthPlan,
      });
    },
    async getAnnualPlan() {
      // this.getthisMonth(date)
      var date = new Date(this.endDate);
      let year = date.getFullYear();
      let url = `/AnnualPlan/GetByYear?year=${year}`;

      const response = await this.ApiService.get(url);

      // JSON responses are automatically parsed.

      // JSON responses are automatically parsed.

      this.AnnualCustomer = response.data.responseData.customers;
      this.AnnualMonths = response.data.responseData.months;
      this.AnnualDaysOff = response.data.responseData.daysOffs;
      this.getAnnualPlanByMonth(this.endDate);
    },
    sameDay(d1, d2) {
      const firstMonth = new Date(d1);
      const secondMonth = new Date(d2);

      return (
        firstMonth.getFullYear() === secondMonth.getFullYear() &&
        firstMonth.getMonth() === secondMonth.getMonth()
      );
    },

    async getCustomer() {
      const response = await this.ApiService.get(
        "https://salestools.yamamacement.com/api/getreportService/getAllCustomers"
      );
      response.data.responseData.forEach((el) => {
        // if (
        //   el.customerNumber == 36 ||
        //   el.customerNumber == 40 ||
        //   el.customerNumber == 95 ||
        //   el.customerNumber == 153 ||
        //   el.customerNumber == 3663 ||
        //   el.customerNumber == 6081 ||
        //   el.customerNumber == 6106 ||
        //   el.customerNumber == 6206 ||
        //   el.customerNumber == 9498 ||
        //   el.customerNumber == 6331 ||
        //   el.customerNumber == 9496 ||
        //   el.customerNumber == 6454 ||
        //   el.customerNumber == 6105 ||
        //   el.customerNumber == 91 ||
        //   el.customerNumber == 6443
        // )
        this.customers.push(el);
      });
    },
    async getTotalMarketSales() {
      this.ItemTable = [];
      let date = moment(this.endDate).format("MM/DD/YYYY");
      this.ItemTable = [];
      let CustomerNameId = [];
      let customerDiff = [];

      const response = await this.ApiService.get(
        `https://salestools.yamamacement.com/api/getreportService/getActualSalesForEachCustomerFromAllAllProductInDay?fromDate=${date}`
      );

      response.data.responseData.forEach((el) => {
        this.customers.forEach((ele) => {
          if (el.customerName == ele.customerName) {
            CustomerNameId.push({
              sumQuantity: el.sumQuantity,
              customerNumber: ele.customerNumber,
              customerName: el.customerName,
              target: 0,
              productbyfactory: 0,
              salesbyfactory: 0,
            });
          }
        });
      });

      this.AnnualCustomer.forEach((el) => {
      
        CustomerNameId.forEach((ele) => {
          if (ele.customerNumber == el.customerId) {
        
            ele.target = el.target;
          }
        });
      });
      CustomerNameId.forEach((el) => {
        customerDiff.push({
          different: el.sumQuantity - el.target,
          differentpercentage: `${(el.sumQuantity - el.target) * 100} %`,
        });
      });
      this.ItemTable = CustomerNameId.map((item, i) =>
        Object.assign({}, item, customerDiff[i])
      );

      this.getFactoryByProduct();
    },
    getFactoryByProduct() {
      let date = moment(this.endDate).format("MM/DD/YYYY");
      let url = `https://salestools.yamamacement.com/api/getreportService/getSalesForEachCustomersByProductAndInDay?fromDate=${date}&productNo=${this.ProductId}`;
      this.ApiService.get(url)
        .then((res) => {
          res.data.forEach((el) => {
            this.ItemTable.forEach((ele) => {
              if (el.customerName == ele.customerName) {
                ele.productbyfactory = el.sumQuantity;
              }
            });
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
thead {
  color: #36304a;
}
.flex.salestable.xs12.md12 {
  padding: 30px;
}
.my-header-style {
  color: #1a6650 !important;
  background-color: #e1ecdb !important;
}
</style>
